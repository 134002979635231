 <script type="text/javascript" src="https://api.longdo.com/map/?key=f77a3947b63bbf9cef1850dfdf0fabd6"></script>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import tableData from "@/components/tablecomponent";
// import GmapCluster from "vue2-google-maps/dist/components/cluster";

/**
 * Advanced-table component
 */

export default {
  page: {
    title: "แผนที่ลูกค้า",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // tableData,
    Layout,
    PageHeader,
    Multiselect,
    btnComponent,
    // GmapCluster,
  }, // DatePicker btnComponent
  data() {
    return {
      // tableData: tableData,

      errormessage: "กรุณาระบุข้อมูล",
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      title: "แผนที่ลูกค้า",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "แผนที่ลูกค้า",
          active: false,
        },
      ],
      viewTypeOption: [
        { id: "P", nameTh: "จังหวัด" },
        { id: "A", nameTh: "อำเภอ" },
        { id: "D", nameTh: "ตำบล" },
      ],
      dataDodge: [],
      dataCountDodge: [],
      numCustomerDodge: "",
      isLoading: false,
      localData: [],
      branchIdSearch: [],
      markerTitle: "",
      sumAmp: "",
      sumPro: "",
      sumAll: "",
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      loading: false,
      urlPrint: "",
      rowsPage: "",
      poId: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      map: "",
      center: { lat: 14.4413702, lon: 100.9329009 },
      filter: {
        supId: "",
        poDate: "",
        poCode: "",
        status: "",
        userBranchId: "",
        branchIdSearch: [],
        provinceId: "",
        amphurId: "",
        year: "",
        viewType: "",
      },
      dataDodgeArr: [],
      viewType: "",
      window_open: false,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
        // maxWidth: 200,
        // content: null,
      },
      locationList: [
        { lon: 99.0, lat: 14 },
        { lon: 100, lat: 13 },
        { lon: 102, lat: 13 },
        { lon: 103, lat: 14 },
      ],
      numCustomer: "",
      numCustomerSale: "",
      numCustomerBooking: "",
      dataCount: [],
      dataMarkers: [],
      dataCus: [],
      isTouched: false,
      // markers: [
      //   {
      //     label: "AAA",
      //     position: {
      //       lat: 10.0,
      //       lng: 10.0,
      //     },
      //   },
      //   {
      //     label: "BBB",
      //     position: {
      //       lat: 15.0,
      //       lng: 13.0,
      //     },
      //   },
      // ],
      isDisabled: false,
      yearOption: [],
      select1: "",
      select2: "",
      select3: "",
      branchIdArr: [],
      text: "",
      radius: "",
      year: "",
      zoom: 6,
      infoPosition: null,
      infoContent: "",
      userBranch: "",
      filterOn: [],
      rowsPO: [],
      sortBy: "age",
      sortDesc: false,
      provinceId: "",
      amphurId: "",
      provinces: [],
      amphures: [],
      submitform: false,
      dataBooking: [],
      dataSale: [],
      circle: "",
      index: "",
      overlays: "",
      arrDodge: [],
      dataMarkersCom: [],
      dataMarkersComArr: [],
      numCustomerCom: 0,
      modelId: "",
      modelOption: [],
      mapOption: [
        { id: "S", name: "ข้อมูลขาย" },
        { id: "B", name: "ข้อมูลจอง" },
        { id: "P", name: "ข้อมูลลูกค้ามุ่งหวัง" },
        { id: "M", name: "ข้อมูลสำรวจตลาด" },
        { id: "D", name: "ลูกค้าผิดชำระ" },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      select: [],
      options: [
        { text: "ปริมาณลูกค้า", value: "2" },
        { text: "ปริมาณลูกค้าผิดนัด", value: "4" },
        { text: "ขอบเขตการปกครอง", value: "3" },
        { text: "ปักหมุด", value: "1" },
      ],
      datas: [
        { lat: 13.729319976238605, lon: 100.53657665848732, value: 8 },
        { lat: 13.727985919380291, lon: 100.53726330399513, value: 10 },
      ],
      markers: [
        { lat: 13.579, lon: 100.123 },
        {
          lat: 15.0,
          lon: 13.0,
        },
      ],
      dataMarkersArr: [],
      // color:"rgb(60, 179, 113)",
      // color: "#b0120a",
      color: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "nameTh",
          sortable: false,
          label: "ตำบล/อำเภอ/จังหวัด",
        },
        {
          key: "numCustomer",
          sortable: false,
          label: "จำนวน",
        },
      ],
      overlayFlag: false,
    };
  },
  validations: {
    filter: {
      year: {
        required,
      },
      viewType: {
        required,
      },
    },
  },
  computed: {},
  mounted() {
    var map = new longdo.Map({
      placeholder: document.getElementById("map"),
      zoom: 6,
    });

    map.location(longdo.LocationMode.Geolocation);

    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
  },
  created() {
    this.getProvinces();
    this.inittahun();
    this.getModel();
  },
  methods: {
    inittahun() {
      let currentYear = new Date().getFullYear();
      let earliestYear = 2001;
      let years = [];
      while (currentYear >= earliestYear) {
        years.push(currentYear);
        currentYear -= 1;
      }
      this.yearOption = years;
    },
    checkNumOne(num) {
      let n = "";
      if (num != undefined) {
        n = Math.floor(num);
      }
      return n;
    },
    checkNumTwo(num) {
      let n = "";
      if (!isNaN(num)) {
        n = parseFloat(num).toFixed(2);
      }
      return n;
    },
    onTouch() {
      this.isTouched = true;
    },
    limitText(count) {
      return `and ${count} other countries`;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    tooltipForm(branchIdSearch) {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.filter.$invalid != true) {
        this.handleSearch(branchIdSearch);
      }
    },
    textTitle(data) {
      return `${data.nameTh} ${
        data.amphuresNameTh != undefined ? data.amphuresNameTh : ""
      } ${data.provinceNameTh != undefined ? data.provinceNameTh : ""}  
      `;
    },
    textDetail(data) {
      return `จำนวน : ${data.numCustomer} คน  <p> พิกัด: ${data.position.lat} ,${data.position.lon}</p>`;
    },
    textDetail2(data) {
      return `จำนวนผิดนัด : ${Math.floor(data.numCustomer)} คน  <p> พิกัด: ${
        data.position.lat
      } ,${data.position.lon}</p>`;
    },
    colorCheck(pin) {
      let color = "";
      if (pin.numCustomer > 3000) {
        color = "rgba(0, 128, 0, 0.7)";
      } else if (pin.numCustomer > 2000) {
        color = "rgba(0, 153, 0, 0.7)";
      } else if (pin.numCustomer > 1000) {
        color = "rgba(0, 204, 0, 0.7)";
      } else if (pin.numCustomer > 750) {
        color = "rgba(0, 255, 0, 0.7)";
      } else if (pin.numCustomer > 500) {
        color = "rgba(142, 255, 0, 0.7)";
      } else if (pin.numCustomer > 250) {
        color = "rgba(172, 255, 0, 0.7)";
      } else if (pin.numCustomer > 225) {
        color = "rgba(200, 255, 0, 0.7)";
      } else if (pin.numCustomer > 200) {
        color = "rgba(255,255,0, 0.7)";
      } else if (pin.numCustomer > 175) {
        color = "rgba(255,255,51, 0.7)";
      } else if (pin.numCustomer > 150) {
        color = "rgba(255,195,0, 0.7)";
      } else if (pin.numCustomer > 125) {
        color = "rgba(255,142,0, 0.7)";
      } else if (pin.numCustomer > 100) {
        color = "rgba(255,112,0, 0.7)";
      } else if (pin.numCustomer > 75) {
        color = "rgba(255,82,0, 0.7)";
      } else if (pin.numCustomer > 50) {
        color = "rgba(255, 51, 51, 0.7)";
      } else if (pin.numCustomer > 25) {
        color = "rgba(255, 0, 0, 0.7)";
      } else {
        color = "rgba(204, 0, 0, 0.7)";
      }

      return color;
    },
    colorCheckDodge(pin, index) {
      let color = "";
      //  if (pin.numCustomer > 3000) {
      //         color = "rgba(0, 210, 225, 0.7)";
      //       } else if (pin.numCustomer > 2000) {
      //         color = "rgba(0, 180, 225, 0.7)";
      //       } else if (pin.numCustomer > 1000) {
      //         color = "rgba(0, 157, 225, 0.7)";
      //       } else if (pin.numCustomer > 750) {
      //         color = "rgba(0, 127, 225, 0.7)";
      //       } else if (pin.numCustomer > 500) {
      //         color = "rgba(0, 120, 225, 0.7)";
      //       } else if (pin.numCustomer > 250) {
      //         color = "rgba(0, 90, 225, 0.7)";
      //       } else if (pin.numCustomer > 225) {
      //         color = "rgba(51, 153, 225, 0.7)";
      //       } else if (pin.numCustomer > 200) {
      //         color = "rgba(51,51,225, 0.7)";
      //       } else if (pin.numCustomer > 175) {
      //         color = "rgba(0,67,225, 0.7)";
      //       } else if (pin.numCustomer > 150) {
      //         color = "rgba(0,30,225, 0.7)";
      //       } else if (pin.numCustomer > 125) {
      //         color = "rgba(0,0,225, 0.7)";
      //       } else if (pin.numCustomer > 100) {
      //         color = "rgba(37, 0, 225, 0.7)";
      //       } else if (pin.numCustomer > 75) {
      //         color = "rgba(75, 0, 225, 0.7)";
      //       } else if (pin.numCustomer > 50) {
      //         color = "rgba(97, 0, 225, 0.7)";
      //       } else if (pin.numCustomer > 25) {
      //         color = "rgba(127, 0, 225, 0.7)";
      //       } else {
      //         color = "rgba(142, 0, 225, 0.7)";
      //       }
      color = "rgba(142, 0, 225, 0.7)";
      return color;
    },
    // loadTest(data) {
    //   console.log(data);
    // },
    loadMap() {
      var map = new longdo.Map({
        placeholder: document.getElementById("map"),
        zoom: 6,
      });
      if (this.dataMarkers.length > 0) {
        this.markerCenter = {
          lat: this.dataMarkers[0].latitude,
          lon: this.dataMarkers[0].longitude,
        };
        if (this.filter.viewType.id === "P") {
          map.location(this.markerCenter);
          map.zoom(6, true);
        } else if (this.filter.viewType.id === "A") {
          map.location(this.markerCenter);

          map.zoom(9, true);
        } else if (this.filter.viewType.id === "D") {
          map.location(this.markerCenter);
          map.zoom(11, true);
        }
      } else {
        this.markerCenter = {
          lat: this.dataMarkersCom[0].latitude,
          lon: this.dataMarkersCom[0].longitude,
        };
        if (this.filter.viewType.id === "P") {
          map.location(this.markerCenter);
          map.zoom(6, true);
        } else if (this.filter.viewType.id === "A") {
          map.location(this.markerCenter);

          map.zoom(9, true);
        } else if (this.filter.viewType.id === "D") {
          map.location(this.markerCenter);
          map.zoom(11, true);
        }
      }

      this.loadCircle(map);
      this.loadOverlays(map);
      // this.getDataMap()
      // if (this.circle) {
      //   console.log(this.circle, "circle");
      //   this.loadCircle(map, this.circle);
      // } else if (this.overlays) {
      //   console.log(this.overlays, "overlays");
      //   this.loadOverlays(map, this.overlays);
      // } else if (this.overlays && this.circle) {
      //   console.log("2");
      //   this.loadOverlays(map, this.overlays);
      //   this.loadCircle(map, this.circle);
      // }
      // if (this.select.length === 1) {
      //   if (this.select.includes("2")) {
      //     this.loadCircle(map);
      //   } else if (this.select.includes("3")) {
      //     this.loadMapOverlays(map);
      //   } else if (this.select.includes("1")) {
      //     this.loadMarker(map);
      //   } else if (this.select.includes("4")) {
      //     this.loadCircleDodge(map);
      //   }
      // } else if (this.select.length === 2) {
      //   if (this.select.includes("2") && this.select.includes("3")) {
      //     this.loadCircle(map);
      //     this.loadMapOverlays(map);
      //   } else if (this.select.includes("3") && this.select.includes("1")) {
      //     this.loadMapOverlays(map);
      //     this.loadMarker(map);
      //   } else if (this.select.includes("1") && this.select.includes("2")) {
      //     this.loadMarker(map);
      //     this.loadCircle(map);
      //   } else if (this.select.includes("1") && this.select.includes("4")) {
      //     this.loadMarker(map);
      //     this.loadCircleDodge(map);
      //   } else if (this.select.includes("2") && this.select.includes("4")) {
      //     this.loadCircle(map);
      //     this.loadCircleDodge(map);
      //   } else if (this.select.includes("3") && this.select.includes("4")) {
      //     this.loadMapOverlays(map);
      //     this.loadCircleDodge(map);
      //   }
      // } else if (this.select.length === 3) {
      //   if (
      //     this.select.includes("1") &&
      //     this.select.includes("2") &&
      //     this.select.includes("3")
      //   ) {
      //     this.loadCircle(map);
      //     this.loadMapOverlays(map);
      //     this.loadMarker(map);
      //   } else if (
      //     this.select.includes("2") &&
      //     this.select.includes("3") &&
      //     this.select.includes("4")
      //   ) {
      //     this.loadMapOverlays(map);
      //     this.loadCircle(map);
      //     this.loadCircleDodge(map);
      //   } else if (
      //     this.select.includes("1") &&
      //     this.select.includes("3") &&
      //     this.select.includes("4")
      //   ) {
      //     this.loadMapOverlays(map);
      //     this.loadMarker(map);
      //     this.loadCircleDodge(map);
      //   } else if (
      //     this.select.includes("1") &&
      //     this.select.includes("2") &&
      //     this.select.includes("4")
      //   ) {
      //     this.loadCircle(map);
      //     this.loadMarker(map);
      //     this.loadCircleDodge(map);
      //   }
      // } else if (this.select.length === 4) {
      //   if (
      //     this.select.includes("1") &&
      //     this.select.includes("2") &&
      //     this.select.includes("3") &&
      //     this.select.includes("4")
      //   ) {
      //     this.loadCircle(map);
      //     this.loadMapOverlays(map);
      //     this.loadMarker(map);
      //     this.loadCircleDodge(map);
      //   }
      // }
    },

    loadCircle(map) {
      if (this.dataMarkersCom.length != 0) {
        this.dataMarkersCom.forEach((item, index) => {
          var circle1 = new longdo.Circle(
            item.position,
            Math.sqrt(item.numCustomer) * 0.005,
            {
              title: this.textTitle(item),
              detail: this.textDetail(item),
              lineWidth: 2,
              lineColor: this.colorCheck(item),
              fillColor: this.colorCheck(item),
            }
          );
          map.Overlays.add(circle1);
        });
      }
    },
    loadOverlays(map) {
      if (this.dataMarkers.length != 0) {
        this.dataMarkers.forEach((item, index) => {
          var marker = new longdo.Marker(item.position, {
            icon: {
              html: `<b>${index + 1}</b>`,
              offset: { x: 5, y: 10 },
            },
          });
          var object1 = new longdo.Overlays.Object(item.code, "IG", {
            title: this.textTitle(item),
            detail: this.textDetail(item),
            ignorefragment: false,
            lineColor: "rgba(0,0,0,0.5)",
            fillColor: this.colorCheck(item, index),
            // fillColor: "rgba(211,211,211,0.5)",
            lineWidth: 1,
            weight: longdo.OverlayWeight.Down,
          });

          if (this.filter.viewType.id === "A") {
            if (
              this.provinceId ? this.provinceId.provinceId : this.provinceId
            ) {
              map.Overlays.add(marker);
              map.Overlays.load(object1);
            }
          } else if (this.filter.viewType.id === "D") {
            if (
              this.provinceId
                ? this.provinceId.provinceId
                : this.provinceId && this.amphurId
                ? this.amphurId.amphureId
                : this.amphurId
            ) {
              map.Overlays.add(marker);
              map.Overlays.load(object1);
            }
          } else {
            map.Overlays.add(marker);
            map.Overlays.load(object1);
          }
        });
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },

    getInfoWindowContent: function (marker) {
      this.markerTitle = marker;
      return this.markerTitle;
    },

    openWindow(marker, idx) {
      this.infoWinOpen = true;
      this.infoContent = this.getInfoWindowContent(marker);
      this.infoWindowPos = marker.position;
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleClusterInfo(cluster) {
      //cluster: Cluster
      //* Extract markers id list from cluster
      const markersIdList = [];
      const gmapMarkers = cluster.getMarkers();
      gmapMarkers.forEach((m) => {
        markersIdList.push(m.getTitle());
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    filterMap(data) {
      const variableOne = this.dataDodge.filter(
        (itemInArray) => itemInArray.markerId === data.markerId
      );
      this.dataMarkersArr = { name: variableOne };
      variableOne.forEach((e) => {
        return e.numCustomer;
      });
    },

    getDataMap: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/customer-map/map", {
          params: {
            branchId: this.branchIdArr,
            "provinceId[]": this.provinceId.provinceId,
            "amphurId[]": this.amphurId.amphureId,
            viewType: this.filter.viewType.id,
            year: this.filter.year,
            dataType: this.overlays ? this.overlays.id : "",
            modelId: this.modelId ? this.modelId.modelId : "",
          },
        })
        .then((response) => {
          if (
            response.data.data.length == 0 ||
            response.data.data.markers.length == 0
          ) {
            if (
              this.circle
                ? this.circle.id != null
                : "" || this.overlays
                ? this.overlays.id != null
                : ""
            )
              //  {

              //   this.showAlert("ไม่พบข้อมูล");
              // }

              this.dataMarkersArr = "";
            this.dataMarkers = "";
            this.numCustomer = 0;
            this.infoWinOpen = false;
          } else {
            if (this.overlays != null) {
              this.getDataMapCom();
            }
            this.dataMarkers = response.data.data.markers;
            this.dataMarkersArr = response.data.data.markers;
            if (this.dataMarkersComArr.length > 0) {
              this.dataMarkersComArr = this.dataMarkersComArr.map((item) => {
                const data = this.dataMarkersArr.filter(
                  (x) => x.markerId === item.markerId
                );
                data["markerId"] = item.markerId;
                data["numCustomer"] = item.numCustomer;
                data["nameTh"] = item.nameTh;
                data["amphuresNameTh"] = item.amphuresNameTh;
                data["provinceNameTh"] = item.provinceNameTh;
                return data;
              });
            }
            let sum = 0;
            this.dataMarkersArr.forEach((e) => {
              sum += parseFloat(e.numCustomer);
            });
            this.numCustomer = sum;
            // console.log(this.dataMarkers, "map");
            this.loadMap();

            // this.loadMapOverlays();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataMapNull: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/customer-map/map", {
          params: {
            branchId: this.branchIdArr,
            "provinceId[]": this.provinceId
              ? this.provinceId.provinceId
              : this.provinceId,
            viewType: this.filter.viewType.id,
            year: this.filter.year,
            dataType: this.overlays ? this.overlays.id : "",
            modelId: this.modelId ? this.modelId.modelId : "",
          },
        })
        .then((response) => {
          if (
            response.data.data.length == 0 ||
            response.data.data.markers.length == 0
          ) {
            if (
              this.circle
                ? this.circle.id != null
                : "" || this.overlays
                ? this.overlays.id != null
                : ""
            )
              // {
              //   this.showAlert("ไม่พบข้อมูล");
              // }
              this.dataMarkersArr = "";
            this.numCustomer = 0;
            this.dataMarkers = "";
            this.infoWinOpen = false;
          } else {
            if (this.overlays != null) {
              this.getDataMapComNull();
            }
            this.dataMarkers = response.data.data.markers;
            this.dataMarkersArr = response.data.data.markers;
            if (this.dataMarkersComArr.length > 0) {
              this.dataMarkersComArr = this.dataMarkersComArr.map((item) => {
                const data = this.dataMarkersArr.filter(
                  (x) => x.markerId === item.markerId
                );
                data["markerId"] = item.markerId;
                data["numCustomer"] = item.numCustomer;
                data["nameTh"] = item.nameTh;
                data["amphuresNameTh"] = item.amphuresNameTh;
                data["provinceNameTh"] = item.provinceNameTh;
                return data;
              });
            }
            let sum = 0;
            this.dataMarkersArr.forEach((e) => {
              sum += parseFloat(e.numCustomer);
            });
            this.numCustomer = sum;

            this.loadMap();
            // this.loadMapOverlays();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataMapNullPro: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/customer-map/map", {
          params: {
            branchId: this.branchIdArr,
            viewType: this.filter.viewType.id,
            year: this.filter.year,
            dataType: this.overlays ? this.overlays.id : "",
            modelId: this.modelId ? this.modelId.modelId : "",
          },
        })
        .then((response) => {
          if (
            response.data.data.length == 0 ||
            response.data.data.markers.length == 0
          ) {
            if (
              this.circle
                ? this.circle.id != null
                : "" || this.overlays
                ? this.overlays.id != null
                : ""
            )
              // {
              //   this.showAlert("ไม่พบข้อมูล");
              // }
              this.dataMarkersArr = "";
            this.numCustomer = 0;
            this.dataMarkers = "";

            this.infoWinOpen = false;
          } else {
            if (this.overlays != null) {
              this.getDataMapComNullPro();
            }
            this.dataMarkers = response.data.data.markers;
            this.dataMarkersArr = response.data.data.markers;
            if (this.dataMarkersComArr.length > 0) {
              this.dataMarkersComArr = this.dataMarkersComArr.map((item) => {
                const data = this.dataMarkersArr.filter(
                  (x) => x.markerId === item.markerId
                );
                data["markerId"] = item.markerId;
                data["numCustomer"] = item.numCustomer;
                data["nameTh"] = item.nameTh;
                data["amphuresNameTh"] = item.amphuresNameTh;
                data["provinceNameTh"] = item.provinceNameTh;
                return data;
              });
            }
            let sum = 0;
            this.dataMarkersArr.forEach((e) => {
              sum += parseFloat(e.numCustomer);
            });
            this.numCustomer = sum;

            this.loadMap();
            // this.loadMapOverlays();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataMapCom: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/customer-map/map", {
          params: {
            branchId: this.branchIdArr,
            "provinceId[]": this.provinceId.provinceId,
            "amphurId[]": this.amphurId.amphureId,
            viewType: this.filter.viewType.id,
            year: this.filter.year,
            dataType: this.circle ? this.circle.id : "",
            modelId: this.modelId ? this.modelId.modelId : "",
          },
        })
        .then((response) => {
          if (
            response.data.data.length == 0 ||
            response.data.data.markers.length == 0
          ) {
            if (
              this.circle
                ? this.circle.id != null
                : "" || this.overlays
                ? this.overlays.id != null
                : ""
            )
              // {
              //   this.showAlert("ไม่พบข้อมูล");
              // }
              this.dataMarkersComArr = "";
            this.dataMarkersCom = "";
            this.numCustomerCom = 0;
            this.infoWinOpen = false;
          } else {
            if (this.circle != null && this.overlays == null) {
              this.getDataMap();
            }
            this.dataMarkersCom = response.data.data.markers;
            this.dataMarkersComArr = response.data.data.markers;
            if (this.dataMarkersArr.length != 0) {
              this.dataMarkersArr = this.dataMarkersArr.map((item) => {
                const data = this.dataMarkersComArr.filter(
                  (x) => x.markerId === item.markerId
                );
                data["markerId"] = item.markerId;
                data["numCustomer"] = item.numCustomer;
                data["nameTh"] = item.nameTh;
                data["amphuresNameTh"] = item.amphuresNameTh;
                data["provinceNameTh"] = item.provinceNameTh;
                return data;
              });
            }
            let sum = 0;
            this.dataMarkersComArr.forEach((e) => {
              sum += parseFloat(e.numCustomer);
            });
            this.numCustomerCom = sum;

            this.loadMap();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataMapComNull: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/customer-map/map", {
          params: {
            branchId: this.branchIdArr,
            "provinceId[]": this.provinceId
              ? this.provinceId.provinceId
              : this.provinceId,
            viewType: this.filter.viewType.id,
            year: this.filter.year,
            dataType: this.circle ? this.circle.id : "",
            modelId: this.modelId ? this.modelId.modelId : "",
          },
        })
        .then((response) => {
          if (
            response.data.data.length == 0 ||
            response.data.data.markers.length == 0
          ) {
            if (
              this.circle
                ? this.circle.id != null
                : "" || this.overlays
                ? this.overlays.id != null
                : ""
            )
              // {
              //   this.showAlert("ไม่พบข้อมูล");
              // }
              this.dataMarkersCom = "";
            this.numCustomerCom = 0;
            this.dataMarkersComArr = "";
            this.infoWinOpen = false;
          } else {
            if (this.circle != null && this.overlays == null) {
              this.getDataMapNull();
            }
            this.dataMarkersCom = response.data.data.markers;
            this.dataMarkersComArr = response.data.data.markers;
            if (this.dataMarkersArr.length != 0) {
              this.dataMarkersArr = this.dataMarkersArr.map((item) => {
                const data = this.dataMarkersComArr.filter(
                  (x) => x.markerId === item.markerId
                );
                data["markerId"] = item.markerId;
                data["numCustomer"] = item.numCustomer;
                data["nameTh"] = item.nameTh;
                data["amphuresNameTh"] = item.amphuresNameTh;
                data["provinceNameTh"] = item.provinceNameTh;
                return data;
              });
              let sum = 0;
              this.dataMarkersComArr.forEach((e) => {
                sum += parseFloat(e.numCustomer);
              });
              this.numCustomerCom = sum;
              this.loadMap();
            }

            // this.loadMapOverlays();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataMapComNullPro: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/customer-map/map", {
          params: {
            branchId: this.branchIdArr,
            viewType: this.filter.viewType.id,
            year: this.filter.year,
            dataType: this.circle ? this.circle.id : "",
            modelId: this.modelId ? this.modelId.modelId : "",
          },
        })
        .then((response) => {
          if (
            response.data.data.length == 0 ||
            response.data.data.markers.length == 0
          ) {
            if (
              this.circle
                ? this.circle.id != null
                : "" || this.overlays
                ? this.overlays.id != null
                : ""
            )
              // {
              //   this.showAlert("ไม่พบข้อมูล");
              // }
              this.dataMarkersComArr = "";
            this.numCustomerCom = 0;
            this.dataMarkersCom = "";

            this.infoWinOpen = false;
          } else {
            if (this.circle != null && this.overlays == null) {
              this.getDataMapNullPro();
            }
            this.dataMarkersCom = response.data.data.markers;
            this.dataMarkersComArr = response.data.data.markers;

            if (this.dataMarkersArr.length != 0) {
              this.dataMarkersArr = this.dataMarkersArr.map((item) => {
                const data = this.dataMarkersComArr.filter(
                  (x) => x.markerId === item.markerId
                );
                data["markerId"] = item.markerId;
                data["numCustomer"] = item.numCustomer;
                data["nameTh"] = item.nameTh;
                data["amphuresNameTh"] = item.amphuresNameTh;
                data["provinceNameTh"] = item.provinceNameTh;
                return data;
              });
              let sum = 0;
              this.dataMarkersComArr.forEach((e) => {
                sum += parseFloat(e.numCustomer);
              });
              this.numCustomerCom = sum;
              this.loadMap();
            }
            // this.loadMapOverlays();
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getModel: function (event) {
      useNetw
        .get("api/customer-map/map/vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchIdUser,
            nameTh: event,
          },
        })
        .then((response) => {
          this.modelOption = response.data.data;
          // console.log( this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
      //  }
    },
    getProvinces: function () {
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
          // console.log( this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
      //  }
    },
    getAmphures: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null
      ) {
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId ? this.provinceId.provinceId : "",
            },
          })
          .then((response) => {
            this.amphures = response.data.data;

            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getDataMap();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDataMap();
    },
    onRowSelected(rowsPO) {
      this.selected = rowsPO;
    },

    handleSearch(branchId) {
      const arr = [];
      branchId.forEach((item) => {
        arr.push(item.branchId);
      });
      this.branchIdArr = arr;

      if (this.amphurId == null && this.provinceId !== null) {
        this.getDataMapNull();
        this.getDataMapComNull();
      } else if (this.provinceId == null) {
        this.getDataMapNullPro();
        this.getDataMapComNullPro();
      } else {
        this.getDataMap();
        this.getDataMapCom();
      }
      if (!this.circle && !this.overlays) {
        this.numCustomer = 0;
        this.numCustomerCom = 0;
        var map = new longdo.Map({
          placeholder: document.getElementById("map"),
          zoom: 6,
        });
        map.Overlays.remove();
      }
    },
  },
  middleware: "authentication",
};
</script>



<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          fade
          variant="danger"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-sm-4 col-md-3">
                          <div class="mb-3 position-relative">
                            <label> สาขา:</label>

                            <multiselect
                              v-model="filter.branchIdSearch"
                              :options="localData"
                              label="nameTh"
                              :show-labels="false"
                              :multiple="true"
                              :searchable="true"
                              :loading="isLoading"
                              :internal-search="false"
                              :clear-on-select="false"
                              :close-on-select="false"
                              :limit-text="limitText"
                              :max-height="600"
                              :show-no-results="false"
                              :hide-selected="true"
                              track-by="branchId"
                            >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-3 position-relative">
                            <label for="provinceId">จังหวัด:</label>

                            <multiselect
                              @place_changed="setPlace"
                              @input="getAmphures(provinceId)"
                              id="autocomplete"
                              v-model="provinceId"
                              :options="provinces"
                              :show-labels="false"
                              label="nameTh"
                              track-by="nameTh"
                              open-direction="bottom"
                            ></multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-3 position-relative">
                            <label for="amphurId">อำเภอ:</label>
                            <multiselect
                              id="amphurId"
                              v-model="amphurId"
                              :options="amphures"
                              :show-labels="false"
                              label="nameTh"
                              track-by="nameTh"
                            ></multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-3 position-relative">
                            <code>* </code><label for="year">ปี(ค.ศ):</label>
                            <multiselect
                              id="year"
                              v-model="filter.year"
                              :options="yearOption"
                              :show-labels="false"
                              :label="index"
                              :track-by="index"
                              :class="{
                                'is-invalid':
                                  submitform && $v.filter.year.$error,
                              }"
                            ></multiselect>
                            <div
                              v-if="submitform && $v.filter.year.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.filter.year.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-3 position-relative">
                            <code>* </code
                            ><label for="amphurId">การแสดงผล:</label>
                            <multiselect
                              id="year"
                              v-model="filter.viewType"
                              :options="viewTypeOption"
                              :show-labels="false"
                              label="nameTh"
                              track-by="id"
                              :class="{
                                'is-invalid':
                                  submitform && $v.filter.viewType.$error,
                              }"
                            ></multiselect>
                            <div
                              v-if="submitform && $v.filter.viewType.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.filter.viewType.required">{{
                                errormessage
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-3 position-relative">
                            <label for="modelId">รุ่นรถ:</label>
                            <multiselect
                              id="modelId"
                              v-model="modelId"
                              :options="modelOption"
                              :show-labels="false"
                              label="nameTh"
                              track-by="modelId"
                              @search-change="getModel($event)"
                            ></multiselect>
                          </div>
                        </div>
                        <!-- <label class="typo-label" for="ajax"
                          >การแสดงรูปแบบแผนที่</label
                        > -->
                        <div class="col-md-3 col-sm-6">
                          <div class="mb-3 position-relative">
                            <label>แบบขอบเขตการปกครอง:</label>
                            <multiselect
                              v-model="overlays"
                              :options="mapOption"
                              :show-labels="false"
                              label="name"
                              track-by="id"
                            ></multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                          <div class="mb-3 position-relative">
                            <label>แบบปริมาณ:</label>
                            <multiselect
                              v-model="circle"
                              :options="mapOption"
                              :show-labels="false"
                              label="name"
                              track-by="id"
                            ></multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-12 text-end mb-2">
                      <btnComponent
                        @click="tooltipForm(filter.branchIdSearch)"
                        changeStyle="search"
                      >
                      </btnComponent>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <div class="row align-items-center ml-5">
                        <label class="typo-label" for="ajax"
                          >การแสดงรูปแบบแผนที่</label
                        >
                        <div class="col-md-3 col-sm-6">
                          <div class="mb-3 position-relative">
                            <label>แบบขอบเขตการปกครอง:</label>
                            <multiselect
                              v-model="overlays"
                              :options="mapOption"
                              :show-labels="false"
                              label="name"
                              track-by="id"
                              @input="dataLoad()"
                            ></multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                          <div class="mb-3 position-relative">
                            <label>แบบปริมาณ:</label>
                            <multiselect
                              v-model="circle"
                              :options="mapOption"
                              :show-labels="false"
                              label="name"
                              track-by="id"
                              @input="dataLoad()"
                            ></multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <span>
                        จำนวนลูกค้า
                        <b>{{ Number(this.numCustomer).toLocaleString() }}</b>
                      </span>
                      คน และ
                      <span v-show="this.circle ? this.circle.id === 'S' : ''"
                        >ข้อมูลขาย</span
                      >
                      <span v-show="this.circle ? this.circle.id === 'B' : ''"
                        >ข้อมูลจอง</span
                      >
                      <span v-show="this.circle ? this.circle.id === 'P' : ''"
                        >ข้อมูลลูกค้ามุ่งหวัง</span
                      >
                      <span v-show="this.circle ? this.circle.id === 'D' : ''"
                        >ผิดชำระ</span
                      ><span>
                        <b>{{
                          Number(this.numCustomerCom).toLocaleString()
                        }}</b>

                        คน</span
                      >
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="row mt-3">
                <div class="col-12 col-sm-4 col-md-4">
                  <b-skeleton-wrapper :loading="loading">
                    <!-- skeleton  -->
                    <template #loading>
                      <b-skeleton-table></b-skeleton-table>
                    </template>
                    <div class="table-responsive">
                      <table class="table table table-bordered">
                        <thead>
                          <tr class="table-active">
                            <th scope="col">#</th>
                            <th scope="col" class="text-center">
                              ตำบล/อำเภอ/จังหวัด
                            </th>
                            <th scope="col" class="text-center">จำนวน</th>
                            <th scope="col" class="text-center">
                              จำนวนเปรียบเทียบ
                            </th>
                            <th scope="col" class="text-center">
                              เปอร์เซ็น (%)
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="this.overlays != null">
                          <tr
                            v-for="(item, index) in dataMarkersArr"
                            :key="index"
                          >
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <b>{{ item.nameTh }}</b>
                              {{ item.amphuresNameTh }}
                              {{ item.provinceNameTh }}
                            </td>
                            <td class="text-end">
                              {{ Math.floor(item.numCustomer) }}
                            </td>
                            <!-- เปรียบเทียบ -->
                            <td
                              class="text-end"
                              v-if="circle ? circle.id != null : ''"
                            >
                              <div v-for="(i, index) in item" :key="index">
                                {{ checkNumOne(i.numCustomer) }}
                              </div>
                            </td>

                            <td
                              class="text-end"
                              v-if="circle ? circle.id != null : ''"
                            >
                              <div v-for="(e, index) in item" :key="index">
                                {{
                                  checkNumTwo(
                                    (e.numCustomer / item.numCustomer) * 100
                                  )
                                }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="this.circle != null">
                          <tr
                            v-for="(item, index) in dataMarkersComArr"
                            :key="index"
                          >
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <b>{{ item.nameTh }}</b>
                              {{ item.amphuresNameTh }}
                              {{ item.provinceNameTh }}
                            </td>
                            <td class="text-end">
                              {{ Math.floor(item.numCustomer) }}
                            </td>
                            <!-- เปรียบเทียบ -->
                            <td
                              class="text-end"
                              v-if="circle ? circle.id != null : ''"
                            >
                              <div v-for="(i, index) in item" :key="index">
                                {{ checkNumOne(i.numCustomer) }}
                              </div>
                            </td>

                            <td
                              class="text-end"
                              v-if="circle ? circle.id != null : ''"
                            >
                              <div v-for="(e, index) in item" :key="index">
                                {{
                                  checkNumTwo(
                                    (e.numCustomer / item.numCustomer) * 100
                                  )
                                }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                  </b-skeleton-wrapper>
                  <!-- <div class="row">
                    <span
                      v-if="this.dataMarkers.length === 0"
                      class="text-center font-size-16"
                      >ไม่พบข้อมูล</span
                    >
                  </div> -->
                </div>
                <div class="col-12 col-sm-8 col-md-8">
                  <div id="map" style="height: 500px"></div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>